import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter } from "react-icons/fi";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundImage: "url(../images/comm_banner.png)",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    "& .copy": {
      borderTop: "1px solid #525252",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
    },

    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: " #1D1D1D",
        fontSize: "14px",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#408FAC",
      fontSize: "15px",
    },
    "& p": {
      maxWidth: "400px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& h6": {
      fontWeight: "400",
      fontSize: "20px",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
        fontSize: "14px !important",
      },
    },
    "& a": {
      color: "#fff",
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "300",
      textDecoration: "none",
      "&:hover": {
        color: "#35a5f5",
        textDecoration: "none",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  },
  flexapp: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  phone: {
    color: "#FFFFFF",
    font: "normal 400 14px/22px 'Poppins'",
    cursor: "pointer",
    "&:hover": {
      color: "#EF802E",
    },
  },
  flexphone: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  mail: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    cursor: "pointer",
    "&:hover": {
      color: "#EF802E",
    },
  },
  subscribe: {
    background: "#0A84FF",
    padding: "4px 0",
    border: "none",
    borderRadius: "4px",
  },
  input: {
    background: "#FFFFFF",
    border: "1.05665px solid #9A9EA5",
    height: "19px",
    borderRadius: "4px",
    paddingLeft: "11px",
  },
  topmargin: {
    marginBottom: "15px",
  },
  follow: {
    font: "normal 700 16px/20px 'Poppins'",
    color: "#FFFFFF",
  },
  followbox: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  content: {
    font: "normal 00 17px/20px 'Poppins'",
    color: "#FFFFFF",
  },
  terms: {
    font: "normal 00 17px/20px 'Poppins'",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      color: "#EF802E",
    },
  },
  flexlogoContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container style={{ gap: "3rem" }}>
            <Grid item xs={4} sm={2}>
              <Box>
                <Box mb={2} className={classes.flexlogoContent}>
                  {" "}
                  <RouterLink to="/">
                    <img
                      src="images/footerimg.svg"
                      style={{ maxWidth: "150px" }}
                    />{" "}
                    <br />
                  </RouterLink>
                  <Typography
                    className={classes.content}
                    style={{
                      textAlignLast: "center",
                      textAlign: "-webkitCenter",
                    }}
                  >
                    GFMI “Generate FLIGHT MODE Income” Services Technology
                    Private Limited.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="h6" className={classes.topmargin}>
                COMPANY
              </Typography>
              <Typography className={classes.content}>
                Office Address: Phase 8B, Industrial Area, Sector 74, SAS Nagar,
                Mohali 160055, Punjab, India
              </Typography>{" "}
              <br />
              <Typography
                className={classes.terms}
                onClick={() => {
                  history.push("/terms");
                }}
              >
                Terms and Conditions
              </Typography>
              <Typography
                className={classes.terms}
                onClick={() => {
                  history.push("/refund-cancellation");
                }}
              >
                Cancellation/Refund Policies
              </Typography>
              <Typography
                className={classes.terms}
                onClick={() => {
                  history.push("/privacy-policy");
                }}
              >
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="h6" className={classes.topmargin}>
                Download GFMI App
              </Typography>
              <div className={classes.flexapp}>
                <img
                  src="images/downloadApple.svg.svg"
                  style={{ width: "7rem" }}
                />
                <img
                  src="images/downloadGoogle.svg.svg"
                  style={{ width: "7rem", height: "3rem" }}
                />
              
              </div>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="h6" className={classes.topmargin}>
                Contact Us
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div className={classes.flexphone}>
                  <img src="images/email.svg" />
                  <div className={classes.phone}>
                    Toll free number 1800-309-1817,
                    <br />
                    Office landline number 0172-418-6576 <br />
                  </div>
                </div>
                <div className={classes.flexphone}>
                  <img src="images/phone.svg" />
                  <div className={classes.phone}>info@gflightmode.com</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="h6" className={classes.topmargin}>
                Subscribe
              </Typography>
              <div className={classes.mail}>
                <input
                  placeholder="Email"
                  type="email"
                  className={classes.input}
                />
                <button className={classes.subscribe}>Subscribe</button>
              </div>
              <Box style={{ marginTop: "41px" }} className={classes.followbox}>
                <div className={classes.follow}>Follow us</div>
                <div style={{ display: "flex", gap: "9px", cursor: "pointer"}}>
                  <img src="images/Facebook-1.png" style={{width: "35px",height: "35px"}}/>
                  <img src="images/Twitter1.png" style={{width: "35px",height: "35px"}}/>
                  <img src="images/Instagram Circle.png" style={{width: "35px",height: "35px"}}/>
                  <img src="images/Youtube.png" style={{width: "35px",height: "35px"}}/>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box className="copy" mt={1} align="center">
            <Typography
              variant="body2"
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "100%",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              © 2023 GFMI, Inc. All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
