import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/gfmi-logo.svg"
      style={{ width: "230px" }}
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
