export default {
  h1: {
    fontWeight: 500,
    fontSize: 49,
    fontFamily: "'Roboto', sans-serif",
  },
  h2: {
    fontWeight: 500,
    fontSize: 46,
    fontFamily: "'Roboto', sans-serif",
    "@media(max-width:767px)": {
      fontSize: 25
    }
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Roboto', sans-serif",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    color: "rgb(25, 170, 230)",
    fontFamily: "'Roboto', sans-serif",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    '@media(max-width:605px)': {
      fontSize: '12px !important',
    },
  },
  fontFamily: "'Roboto', sans-serif",

  body1: {
    color: "#404040", 
    fontSize: "14px", 
    fontFamily: "'Roboto', sans-serif", 
    fontWeight: "400", 
    lineHeight: "20px",
  },
  body2: {
    color: "#fff", 
    fontSize: "14px", 
    fontFamily: "'Roboto', sans-serif", 
    fontWeight: "300", 
    lineHeight: "20px",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Roboto', sans-serif",
  },
};
