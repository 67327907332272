import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "../src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("../src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("../src/views/pages/Static/About")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("../src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("../src/views/pages/Static/Disclaimer")),
  },
  {
    exact: true,
    path: "/refund-cancellation",
    layout: HomeLayout,
    component: lazy(() => import("../src/views/pages/Static/Refund")),
  },
  // {
  //   exact: true,
  //   path: "/profile",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/HorseProfile/Profile")),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("../src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
